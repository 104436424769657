<template>
    <div class="chartItem">
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="采集区域统计" name="1">
                <div class="chartBox" id="chartBox7"></div>
            </el-tab-pane>
        </el-tabs>
        <div class="queryForm" v-if="userInfo.userType != 2">
            <el-cascader filterable :props="{ checkStrictly: true }" placeholder="请选择区域" size="large" v-model="region" :options="options" @change="getStatisticsArea"></el-cascader>
        </div>
    </div>
</template>
<script>
import { regionData, CodeToText, TextToCode } from "element-china-area-data";
import { mapGetters } from "vuex";
import { queryRegionList } from "@/api/user";
import { statisticsArea } from "@/api/statistcalCenter";
import { solution } from "@/utils";
export default {
    data() {
        return {
            options: regionData,
            activeName: "1",
            region: [],
            chartData: [],
        };
    },
    computed: {
        ...mapGetters(["userInfo"]),
    },
    created() {
        if (this.userInfo.userType == 3) {
            this.region = [TextToCode["湖南省"].code];
            this.getStatisticsArea();
        } else if (this.userInfo.userType == 1) {
            this.getRegion();
        } else {
            this.region = [TextToCode[this.userInfo.province].code, TextToCode[this.userInfo.province][this.userInfo.city].code, TextToCode[this.userInfo.province][this.userInfo.city][this.userInfo.district].code];
            this.getStatisticsArea();
        }
    },
    methods: {
        getRegion() {
            let params = {
                userCode: this.userInfo.userCode,
            };
            const _this = this;
            queryRegionList(params)
                .then((res) => {
                    let dataList = res.data.dataList;
                    const keys = ["province", "city", "district"];
                    // dataList 是一个扁平的数组，是需要转换成城市三级级联数组才能给级联器使用
                    let list = solution(dataList, keys);
                    list.map((pItem) => {
                        pItem.label = pItem.value;
                        pItem.value = TextToCode[pItem.label].code;
                        pItem.children.map((cItem) => {
                            cItem.label = cItem.value;
                            cItem.value = TextToCode[pItem.label][cItem.label].code;
                            cItem.children.map((dItem) => {
                                dItem.label = dItem.value;
                                dItem.value = TextToCode[pItem.label][cItem.label][dItem.label].code;
                            });
                        });
                    });
                    _this.options = list;
                    _this.region = [list[0].value];
                    _this.getStatisticsArea();
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        updataChart() {
            let chartId = "chartBox7";
            let xAxisData = [];
            let seriesData = [];
            let chartData = this.chartData;
            chartData.map((item) => {
                seriesData.push(item.totalCount);
                if (this.region.length == 1) {
                    xAxisData.push(item.city);
                } else {
                    xAxisData.push(item.district);
                }
            });
            // 基于准备好的dom，初始化echarts实例
            let chart = this.$echarts.init(document.getElementById(chartId));
            // 指定图表的配置项和数据
            var option = {
                tooltip: {
                    trigger: "axis",
                    extraCssText: "width:200px!important;",
                    axisPointer: {
                        type: "shadow",
                    },
                    formatter: function (params) {
                        var tar = params[0];
                        return tar.name + " : " + tar.value;
                    },
                },
                xAxis: {
                    type: "category",
                    data: xAxisData,
                },
                yAxis: {
                    type: "value",
                },
                series: [
                    {
                        data: seriesData,
                        type: "bar",
                        showBackground: true,
                        label: {
                            show: true,
                            position: "inside",
                        },
                        backgroundStyle: {
                            color: "rgba(180, 180, 180, 0.2)",
                        },
                    },
                ],
            };
            chart.setOption(option);
            this.chart = chart;
        },
        getStatisticsArea() {
            let params = {};
            if (this.userInfo.userType == 1) {
                params.taskUserCode = this.userInfo.userCode;
            }
            if (this.region.length == 1) {
                params.province = CodeToText[this.region[0]];
            } else if (this.region.length == 2) {
                params.province = CodeToText[this.region[0]];
                params.city = CodeToText[this.region[1]];
            } else if (this.region.length == 3) {
                params.province = CodeToText[this.region[0]];
                params.city = CodeToText[this.region[1]];
                params.district = CodeToText[this.region[2]];
            }
            const _this = this;
            statisticsArea(params)
                .then((res) => {
                    _this.chartData = res.data.sort(function (a, b) {
                        return b.totalCount - a.totalCount;
                    });
                    if (this.chart) {
                        this.chart.dispose();
                        this.chart = null;
                    }
                    _this.updataChart();
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        handleClick() {
            if (this.chart) {
                this.chart.dispose();
                this.chart = null;
            }
            this.getStatisticsArea();
        },
    },
};
</script>
<style lang="less" scoped>
.chartItem {
    position: relative;
    background: #ffffff;
    border-radius: 10px;
    padding: 40px 28px 0px;
    .chartBox {
        height: 400px;
        /deep/ div {
            width: 100% !important;
            canvas {
                width: 100% !important;
            }
        }
    }
    .queryForm {
        position: absolute;
        top: 14px;
        right: 16px;
        text-align: right;
    }
}
</style>
