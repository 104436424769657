import request from '@/utils/request'

/**
 * 查询任务数量统计
 * @param {*} 
 * @returns  
 */
export function homePageTaskCountStatistics (data) {
    return request({
        url: '/statistics/homePageTaskCountStatistics',
        method: 'post',
        data
    })
}

/**
 * 查询统计图表
 * @param {*} 
 * @returns  
 */
export function indexStatisticsChart (data) {
    return request({
        url: '/statistics/indexStatisticsChart',
        method: 'post',
        data
    })
}

/**
 * 查询任务领取统计
 * @param {*} 
 * @returns  
 */
export function taskReceiveStatistics (data) {
    return request({
        url: '/statistics/taskReceiveStatistics',
        method: 'post',
        data
    })
}

/**
 * 查询行业类型统计
 * @param {*} 
 * @returns  
 */
export function statisticsIndustry (data) {
    return request({
        url: '/statistics/statisticsIndustry',
        method: 'post',
        data
    })
}

/**
 * 查询媒介类型统计
 * @param {*} 
 * @returns  
 */
export function statisticsMediaType (data) {
    return request({
        url: '/statistics/statisticsMediaType',
        method: 'post',
        data
    })
}

/**
 * 查询采集区域统计
 * @param {*} 
 * @returns  
 */
export function statisticsArea (data) {
    return request({
        url: '/statistics/statisticsArea',
        method: 'post',
        data
    })
}