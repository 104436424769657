<template>
    <div class="chartItem">
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="任务统计" name="1">
                <div class="chartBox" id="chartBox"></div>
            </el-tab-pane>
        </el-tabs>
        <div class="queryForm">
            <el-select @change="searchTypeChange" style="width: 20%" size="mini" v-model="searchType" placeholder="请选择日期类型">
                <el-option label="按天" value="1"></el-option>
                <el-option label="按月" value="2"></el-option>
                <el-option label="按年" value="3"></el-option>
            </el-select>
            <template v-if="searchType != 3">
                <el-date-picker
                    :key="3"
                    :clearable="false"
                    @input="getIndexStatisticsChart"
                    style="width: 55%"
                    v-model="queryDate"
                    :type="searchType == 1 ? 'daterange' : 'monthrange'"
                    size="mini"
                    align="right"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                >
                </el-date-picker>
            </template>
            <template v-else>
                <el-date-picker :key="1" @change="getIndexStatisticsChart" :clearable="false" v-model="yearStartTime" style="width: 25%" size="mini" align="right" type="year" placeholder="开始日期" :picker-options="pickerStartAuditYear"></el-date-picker>
                <span class="marginLeft-8 marginRight-8">至</span>
                <el-date-picker :key="2" @change="getIndexStatisticsChart" :clearable="false" v-model="yearEndTime" style="width: 25%" size="mini" align="right" type="year" placeholder="开始日期" :picker-options="pickerEndAuditYear"></el-date-picker>
            </template>
        </div>
    </div>
</template>
<script>
import moment from "moment";
import { indexStatisticsChart } from "@/api/home";
export default {
    data() {
        return {
            activeName: "1",
            yearStartTime: "",
            yearEndTime: "",
            searchType: "1",
            queryDate: "",
            pickerStartAuditYear: {
                disabledDate: (time) => {
                    if (this.yearEndTime) {
                        return time.getFullYear() > moment(this.yearEndTime).format("YYYY");
                    }
                },
            },
            pickerEndAuditYear: {
                disabledDate: (time) => {
                    return time.getFullYear() < moment(this.yearStartTime).format("YYYY");
                },
            },
            chartData: [],
        };
    },
    created() {
        if (this.searchType == 1) {
            this.queryDate = [moment().subtract(7, "days"), moment()];
        } else if (this.searchType == 2) {
            this.queryDate = [moment().subtract(5, "months"), moment()];
        } else if (this.searchType == 3) {
            this.yearStartTime = moment().subtract(5, "years");
            this.yearEndTime = moment();
        }
        this.getIndexStatisticsChart();
    },
    methods: {
        updataChart() {
            let legengData = ["任务数"];
            let series = [];
            let xAxisData = [];
            let chartData = this.chartData;
            let seriesData = [];
            let chartId = "chartBox";
            if (this.searchType == 1) {
                let day = moment(this.queryDate[1]).diff(moment(this.queryDate[0]), "day");
                for (let i = 0; i < day + 1; i++) {
                    xAxisData.push(moment(this.queryDate[0]).add(i, "days").format("MM-DD"));
                    seriesData.push(0);
                }
            } else if (this.searchType == 2) {
                let month = moment(this.queryDate[1]).diff(moment(this.queryDate[0]), "month");
                for (let i = 0; i < month + 1; i++) {
                    xAxisData.push(moment(this.queryDate[0]).add(i, "months").format("YYYY-MM"));
                    seriesData.push(0);
                }
            } else if (this.searchType == 3) {
                let year = moment(this.yearEndTime).diff(moment(this.yearStartTime), "year");
                for (let i = 0; i < year + 1; i++) {
                    xAxisData.push(moment(this.yearStartTime).add(i, "years").format("YYYY"));
                    seriesData.push(0);
                }
            }
            xAxisData.map((item, index) => {
                chartData.map((chartItem) => {
                    if (this.searchType == 1) {
                        if (item == moment(chartItem.date).format("MM-DD")) {
                            seriesData[index] = chartItem.totalCount;
                        }
                    }
                    if (this.searchType == 2) {
                        if (item == moment(chartItem.date).format("YYYY-MM")) {
                            seriesData[index] = chartItem.totalCount;
                        }
                    }
                    if (this.searchType == 3) {
                        if (item == moment(chartItem.date).format("YYYY")) {
                            seriesData[index] = chartItem.totalCount;
                        }
                    }
                });
            });
            series = [
                {
                    name: "任务数",
                    data: seriesData,
                    type: "line",
                    smooth: true,
                },
            ];
            if (this.activeName == 1) {
                chartId = "chartBox";
                legengData = ["任务数"];
                series[0].name = "任务数";
            }
            if (this.chart) {
                //刷新数据
                var option = this.chart.getOption();
                option.legend.data = legengData;
                option.xAxis = {
                    type: "category",
                    data: xAxisData,
                };
                option.series[0].data = seriesData;
                this.chart.setOption(option, true);
            } else {
                // 基于准备好的dom，初始化echarts实例
                let chart = this.$echarts.init(document.getElementById(chartId));
                // 指定图表的配置项和数据
                var option = {
                    title: {
                        text: "",
                    },
                    tooltip: {},
                    legend: {
                        data: legengData,
                    },
                    xAxis: {
                        type: "category",
                        data: xAxisData,
                    },
                    yAxis: {
                        type: "value",
                    },
                    series: series,
                };
                chart.setOption(option);
                this.chart = chart;
            }
        },
        getIndexStatisticsChart() {
            let params = {
                searchType: Number(this.searchType),
                type: Number(this.activeName),
            };
            if (this.searchType == 1) {
                params.beginTime = moment(this.queryDate[0]).format("YYYY-MM-DD") + " 00:00:00";
                params.endTime = moment(this.queryDate[1]).format("YYYY-MM-DD") + " 23:59:59";
            } else if (this.searchType == 2) {
                params.beginTime = moment(this.queryDate[0]).startOf("month").format("YYYY-MM-DD") + " 00:00:00";
                params.endTime = moment(this.queryDate[1]).endOf("month").format("YYYY-MM-DD") + " 23:59:59";
            } else if (this.searchType == 3) {
                params.beginTime = moment(this.yearStartTime).startOf("year").format("YYYY-MM-DD") + " 00:00:00";
                params.endTime = moment(this.yearEndTime).endOf("year").format("YYYY-MM-DD") + " 23:59:59";
            }
            const _this = this;
            indexStatisticsChart(params)
                .then((res) => {
                    _this.chartData = res.data.dataList;
                    _this.updataChart();
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        handleClick() {
            if (this.chart) {
                this.chart.dispose();
                this.chart = null;
            }
            this.getIndexStatisticsChart();
        },
        searchTypeChange() {
            if (this.searchType == 1) {
                this.queryDate = [moment().subtract(7, "days"), moment()];
            } else if (this.searchType == 2) {
                this.queryDate = [moment().subtract(5, "months"), moment()];
            } else if (this.searchType == 3) {
                this.yearStartTime = moment().subtract(5, "years");
                this.yearEndTime = moment();
            }
            this.getIndexStatisticsChart();
        },
    },
};
</script>
<style lang="less" scoped>
.chartItem {
    position: relative;
    background: #ffffff;
    border-radius: 10px;
    padding: 40px 28px 0px;
    .chartBox {
        height: 292px;
    }
    .queryForm {
        position: absolute;
        top: 14px;
        right: 16px;
        text-align: right;
    }
}
</style>
