<template>
    <div class="chartItem">
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="媒介类型统计" name="1">
                <div class="chartBox" id="chartBox5"></div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
import { statisticsMediaType } from "@/api/statistcalCenter";
import { mapGetters } from "vuex";
export default {
    data() {
        return {
            activeName: "1",
            chartData: [],
        };
    },
    created() {
        this.getStatisticsMediaType();
    },
    computed: {
        ...mapGetters(["userInfo"]),
    },
    methods: {
        updataChart() {
            let legendData = [];
            let seriesData = [];
            let chartData = this.chartData;
            chartData.map((item) => {
                legendData.push(item.mediaType);
                let obj = {
                    name: item.mediaType,
                    value: item.totalCount,
                };
                seriesData.push(obj);
            });
            let chartId = "chartBox5";
            if (this.activeName == 1) {
                chartId = "chartBox5";
            }
            if (this.chart) {
                //刷新数据
                var option = this.chart.getOption();
                option.legend.data = legengData;
                option.series[0].data = seriesData;
                this.chart.setOption(option, true);
            } else {
                // 基于准备好的dom，初始化echarts实例
                let chart = this.$echarts.init(document.getElementById(chartId));
                // 指定图表的配置项和数据
                var option = {
                    tooltip: {
                        trigger: "item",
                        formatter: "{a} <br/>{b} : {c} ({d}%)",
                    },
                    legend: {
                        type: "scroll",
                        orient: "vertical",
                        right: 10,
                        top: 20,
                        bottom: 20,
                        data: legendData,
                    },
                    series: [
                        {
                            name: "媒介类型",
                            type: "pie",
                            radius: "80%",
                            center: ["40%", "50%"],
                            data: seriesData,
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: "rgba(0, 0, 0, 0.5)",
                                },
                            },
                        },
                    ],
                };
                chart.setOption(option);
                this.chart = chart;
            }
        },
        getStatisticsMediaType() {
            let params = {};
            if (this.userInfo.userType == 1) {
                params.taskUserCode = this.userInfo.userCode;
            }
            const _this = this;
            statisticsMediaType(params)
                .then((res) => {
                    _this.chartData = res.data.sort(function (a, b) {
                        return b.totalCount - a.totalCount;
                    });
                    _this.updataChart();
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        handleClick() {
            if (this.chart) {
                this.chart.dispose();
                this.chart = null;
            }
            this.getIndexStatisticsChart();
        },
    },
};
</script>
<style lang="less" scoped>
.chartItem {
    position: relative;
    background: #ffffff;
    border-radius: 10px;
    padding: 40px 28px 0px;
    .chartBox {
        height: 292px;
    }
    .queryForm {
        position: absolute;
        top: 14px;
        right: 16px;
        text-align: right;
    }
}
</style>
