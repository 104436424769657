<template>
    <div class="app-container" v-if="userInfo.userType != 2">
        <el-row :gutter="16">
            <el-col :span="24">
                <el-row :gutter="16" class="marginBottom-16">
                    <el-col :span="6">
                        <statistics-item mainTitle="已发布的任务" subTitle="今日发布" :todayNum="TaskNum.todayNotCount" :totalNum="TaskNum.notCount"></statistics-item>
                    </el-col>
                    <el-col :span="6">
                        <statistics-item mainTitle="采集中的任务" subTitle="今日采集" :todayNum="TaskNum.todayIngCount" :totalNum="TaskNum.ingCount"></statistics-item>
                    </el-col>
                    <el-col :span="6">
                        <statistics-item mainTitle="审核中的任务" subTitle="今日审核" :todayNum="TaskNum.todayCheckCount" :totalNum="TaskNum.checkCount"></statistics-item>
                    </el-col>
                    <el-col :span="6">
                        <statistics-item mainTitle="已上报任务" subTitle="今日上报" :todayNum="TaskNum.todayReportCount" :totalNum="TaskNum.reportCount"></statistics-item>
                    </el-col>
                </el-row>
                <el-row :gutter="16">
                    <el-col :span="6">
                        <statistics-item mainTitle="采集账号总数" subTitle="今日新增" :todayNum="TaskNum.newUserCount" :totalNum="TaskNum.totalUserCount"></statistics-item>
                    </el-col>
                    <el-col :span="6">
                        <statistics-item mainTitle="总点位" subTitle="今日新增" :todayNum="TaskNum.newPointCount" :totalNum="TaskNum.totalPointCount"></statistics-item>
                    </el-col>
                    <el-col :span="6">
                        <statistics-item mainTitle="采集图片" subTitle="今日新增" :todayNum="TaskNum.newDataCount" :totalNum="TaskNum.dataCount"></statistics-item>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
        <el-row :gutter="16" class="marginTop-16">
            <el-col :span="8">
                <chart-1></chart-1>
            </el-col>
            <el-col :span="8">
                <chart-2></chart-2>
            </el-col>
            <el-col :span="8">
                <chart-3></chart-3>
            </el-col>
        </el-row>
        <el-row :gutter="16" class="marginTop-16">
            <el-col :span="12">
                <chart-5></chart-5>
            </el-col>
            <el-col :span="12">
                <chart-6></chart-6>
            </el-col>
        </el-row>
        <el-row :gutter="16" class="marginTop-16">
            <el-col :span="24">
                <chart-7></chart-7>
            </el-col>
        </el-row>
    </div>
    <div class="app-container" v-else>
        <el-row :gutter="16">
            <el-col :span="24">
                <el-row :gutter="16" class="marginBottom-16">
                    <el-col :span="6">
                        <statistics-item mainTitle="采集中的任务" subTitle="今日采集" :todayNum="TaskNum.todayIngCount" :totalNum="TaskNum.ingCount"></statistics-item>
                    </el-col>
                    <el-col :span="6">
                        <statistics-item mainTitle="已上报任务" subTitle="今日上报" :todayNum="TaskNum.todayReportCount" :totalNum="TaskNum.reportCount"></statistics-item>
                    </el-col>
                    <el-col :span="6">
                        <statistics-item mainTitle="审核中的任务" subTitle="今日审核" :todayNum="TaskNum.todayCheckCount" :totalNum="TaskNum.checkCount"></statistics-item>
                    </el-col>
                    <el-col :span="6">
                        <statistics-item mainTitle="采集图片" subTitle="今日新增" :todayNum="TaskNum.newDataCount" :totalNum="TaskNum.dataCount"></statistics-item>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
        <el-row :gutter="16" class="marginTop-16">
            <el-col :span="12">
                <chart-4></chart-4>
            </el-col>
            <el-col :span="12">
                <chart-3></chart-3>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import StatisticsItem from "./components/StatisticsItem";
import { homePageTaskCountStatistics } from "@/api/statistcalCenter";
import { mapGetters } from "vuex";
import Chart1 from "./components/Chart1";
import Chart2 from "./components/Chart2";
import Chart3 from "./components/Chart3";
import Chart4 from "./components/Chart4";
import Chart5 from "./components/Chart5";
import Chart6 from "./components/Chart6";
import Chart7 from "./components/Chart7";
export default {
    components: { StatisticsItem, Chart1, Chart2, Chart3, Chart4, Chart5, Chart6, Chart7 },
    data() {
        return {
            TaskNum: {
                checkCount: 0,
                dataCount: 0,
                ingCount: 0,
                newDataCount: 0,
                newPointCount: 0,
                notCount: 0,
                reportCount: 0,
                todayCheckCount: 0,
                todayIngCount: 0,
                todayNotCount: 0,
                todayReportCount: 0,
                totalPointCount: 0,
            },
        };
    },
    created() {
        this.getTaskNum();
    },
    mounted() {},
    computed: {
        ...mapGetters(["userInfo"]),
    },
    methods: {
        getTaskNum() {
            let params = {};
            const _this = this;
            homePageTaskCountStatistics(params)
                .then((res) => {
                    _this.TaskNum = res.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
};
</script>
<style lang="less" scoped>
.app-container {
    padding: 16px 32px;
    .tabsContainer {
        background: #fff;
        padding: 8px;
        .tabsBox {
            position: relative;
            .chartBox {
                height: 300px;
            }
            .queryForm {
                position: absolute;
                top: 4px;
                right: 32px;
                text-align: right;
            }
        }
    }
}
</style>
