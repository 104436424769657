<template>
    <div class="StatisticsItem">
        <el-row class="mainTitle marginBottom-8">
            {{ mainTitle }}
        </el-row>
        <el-row class="totalNum marginBottom-8">
            <VueCountUp :end-value="totalNum" :duration="2" />
        </el-row>
        <el-divider class="divider"></el-divider>
        <el-row class="box1">
            <el-col :span="12" class="subTitle">{{ subTitle }}</el-col>
            <el-col :span="12" class="todayNum">
                <VueCountUp :end-value="todayNum" :duration="2" />
            </el-col>
        </el-row>
        <el-divider class="divider"></el-divider>
    </div>
</template>
<script>
import VueCountUp from "vue-countupjs";
export default {
    components: { VueCountUp },
    props: ["mainTitle", "subTitle", "todayNum", "totalNum"],
};
</script>
<style lang="less" scoped>
.StatisticsItem {
    background: #fff;
    border-radius: 10px;
    padding: 22px 28px;
    .box1 {
        padding: 10px 0;
        display: flex;
        align-items: center;
    }
    .box2 {
        padding-bottom: 0;
    }
    .divider {
        margin: 0 0 8px;
        background: #eee;
    }
    .mainTitle,
    .todayNum {
        font-size: 20px;
        font-weight: 600;
    }
    .totalNum {
        font-size: 36px;
        color: #f68989;
        font-weight: 600;
    }
    .todayNum,
    .img {
        text-align: right;
    }
}
</style>
