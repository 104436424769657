import request from '@/utils/request'

/**
 * 首页查询任务数量统计
 * @param {*} 
 * @returns  
 */
export function homePageTaskCountStatistics (data) {
    return request({
        url: '/statistics/homePageTaskCountStatistics',
        method: 'post',
        data
    })
}

/**
 * 首页查询统计图标
 * @param {*} 
 * @returns  
 */
export function indexStatisticsChart (data) {
    return request({
        url: '/statistics/indexStatisticsChart',
        method: 'post',
        data
    })
}

/**
 * 首页查询任务领取统计
 * @param {*} 
 * @returns  
 */
export function taskReceiveStatistics (data) {
    return request({
        url: '/statistics/taskReceiveStatistics',
        method: 'post',
        data
    })
}